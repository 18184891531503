.wrapper {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.image {
	height: 400px;
	margin-left: 50px;
}

.logo {
	height: 200px;
}

@media screen and (max-width: 900px) {
	.wrapper {
		flex-direction: column;
	}
	.image {
		margin-left: 0;
		width: 90%;
		height: unset;
	}
}
