body {
	margin: 0;
	font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// ====================================================================================================
// минимальный размер страницы 100% и растягивание в случае, если контент не умещается
// частично взято отсюда https://habr.com/ru/post/483634/
// ====================================================================================================
html {
	height: 100%;
	width: 100%;
}

body,
#root {
	min-height: 100%;
	height: 100%;
	width: 100%;
}
// ====================================================================================================
